.imageBox {
    width: 130px;
    height: 130px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    color: white;
    font-weight: bold;
}

.nameBox {
    position: absolute;
    bottom: 25px;
    left: -31px;
    width: 200px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
}

.nameBox__green {
    background-color: #3cb371bb;
}

.imageBox__green {
    border: 6px solid #3cb371;
}

.nameBox__blue {
    background-color: #6495edbb;
}

.imageBox__blue {
    border: 6px solid #6495ed;
}

.nameBox__grey {
    background-color: #708090bb;
}

.imageBox__grey {
    border: 6px solid #708090;
}

.nameBox__black {
    background-color: #000000bb;
}

.imageBox__black {
    border: 6px solid #000000;
}

.nameBox__yellow {
    background-color: #f2f26fbb;
    color: #414141;
}

.imageBox__yellow {
    border: 6px solid #f2f26f;
}

.nameBox__red {
    background-color: #cd5c5cbb;
}

.imageBox__red {
    border: 6px solid #cd5c5c;
}

.nameBox__white {
    background-color: #f9f9f9bb;
    color: #414141;
}

.imageBox__white {
    border: 6px solid #f9f9f9;
}

.nameBox__brown {
    background-color: #f5ad98bb;
    color: #414141;
}

.imageBox__brown {
    border: 6px solid #f5ad98;
}

.nameBox__purple {
    background-color: #9b9ccabb;
}

.imageBox__purple {
    border: 6px solid #9b9cca;
}

.nameBox__pink {
    background-color: #ad9ab4bb;
}

.imageBox__pink {
    border: 6px solid #ad9ab4;
}

.nameBox__orange {
    background-color: #da8a60bb;
}

.imageBox__orange {
    border: 6px solid #da8a60;
}

.characterBoxImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0% 0%;
}

.miniAvatarContainer {
    width: 42px;
    height: 42px;
    overflow: hidden;
    position: relative;
}

.miniAvatarImage {
    width: 100%;
    height: 100%;
    border-radius: 130px;
    object-fit: cover;
    object-position: 0% 0%;
}