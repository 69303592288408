.basicDataGridContainer {
    display: grid;
    grid-template-columns: 150px 100px 200px 110px 200px 100px 100px 100px 100px 100px;
    align-items: center;
    justify-items: center;
    justify-content: start;
    row-gap: 15px;
    column-gap: 20px;
    margin: 30px;
}

.basicDataItem {
    color: white;
}

.basicDataHeader { 
    color: white;
    font-weight: bold;
    user-select: none;
}

.basicDataFilter {
    background-color: #515151;
    width: 100%;
    color: white;
    grid-column-start: 1;
    grid-column-end: 6;
}