.detailedDataCardContainer {
  margin: 30px;
  display: flex;
}

.statListContainer {
  margin: 0px 40px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 300px;
  width: 580px;
}

.statListItemContainer {
  display: flex;
  width: 170px;
  justify-content: space-between;
  margin: 4px 4px;
  padding: 8px;
  background-color: #323232;
}

.statListItemName {
  color: white;
  font-weight: 200;
  font-size: large;
}

.statListItemValue {
  color: white;
  font-weight: 600;
  font-size: medium;
}

.levelDisplayContainer {
  margin-top: 10px;
}

.levelDisplayLevel {
  color: white;
  font-size: larger;
  font-weight: 600;
  margin-bottom: -7px;
}

.xpBarContainer {
}

.xpBarText {
  color: white;
  display: flex;
  align-items: baseline;
}

.xpBarTextValue {
  font-weight: 500;
  font-size: 17px;
}

.xpBarTextMax {
  font-weight: 200;
  margin-left: 4px;
  font-size: 12px;
}

progress.xpBar { 
  width: 142px;
  height: 10px;
  -webkit-appearance: none;
  appearance: none;
}

progress.xpBar::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 5px;
}

progress.xpBar::-webkit-progress-value {
  border-radius: 5px;
}

.detailedDataCardAvatarLevel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

progress.xpBar__blue::-webkit-progress-value {
  background-color: #6495ed;
}

progress.xpBar__hpRed::-webkit-progress-value {
  background-color: #b23415;
}

.statPointDistributionCardContainer {
  display: grid;
  grid-template-columns: 140px 140px;
  margin: 20px;
  color: white;
  height: fit-content;
}

.statPointDistributionItemContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
  margin: 5px 0px;
  padding: 8px 5px;
  background-color: #323232;
}

.statPointDistributionItemName {
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 4px;
}

.statPointDistributionItemBottomRow {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
}

.statPointDistributionItemSign {
  font-size: 18px;
  font-weight: 600;
  user-select: none;
  padding: 2px;
  width: 15px;
  text-align: center;
  margin-bottom: 4px;
  background-color: #606060;
}

.statPointDistributionItemSign:hover {
  background-color: #888888;
}

.statPointDistributionItemValue {
  margin-left: 5px;
  margin-right: 3px;
  font-size: 16px;
}

.statPointDistributionItemValuePointsSpent {
  margin-right: 5px;
  font-size: 12px;
  font-weight: 300;
}

.statPointDistributionItemSignColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statPointDistributionItemPointCost {
  font-size: 10px;
  font-weight: 200;
}

.statsChartContainer {
  width: 350px;
  height: 350px;
}

.resultsChartContainer {
  width: 600px;
  height: 400px;
  margin-top: 30px;
}

.statsWithChartContainer {
  display: flex;  
  flex-direction: column;
  align-items: center;
}